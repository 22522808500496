import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';
import Head from 'next/head';
import { GetServerSideProps } from 'next';
import TitheEnvelope from '@components/templates/donate/tithe-envelope';
import { fetchPrivilegedClientOnlyRoute } from '@fetch/helpers';

export const titleMessage = defineMessages({
  title: {
    id: 'p0nCGh',
    defaultMessage: 'Donate',
    description: 'Title for Donate page.',
  },
});
const DonateTitheEnvelopePage = (data: any) => {
  const router = useRouter();
  const intl = useIntl();
  return (
    <>
      <Head>
        <title>
          {`AdventistGiving - ${intl.formatMessage(titleMessage.title)}`}
        </title>
      </Head>
      <TitheEnvelope organization={data.org} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const response = await fetchPrivilegedClientOnlyRoute({
    url: `${process.env.API_URL}/v3/organizations/${context.query.orgId}`,
    req: context.req as any,
  });
  if (response.ok) {
    const data = await response.json();
    if (data.status === 'inactive') {
      return {
        redirect: {
          permanent: false,
          destination: '/?notEnrolledId=' + context.query.orgId,
        },
      };
    } else if (data.status === 'active') {
      return {
        props: { org: data },
      };
    }
  }
  return {
    notFound: true,
  };
};

export default DonateTitheEnvelopePage;
